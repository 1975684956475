<template>
    <div>
        <el-card>
            <div class="department-box">
                <div class="department-class">
                    <div class="class-left-up">
                        <p><i class="el-icon-folder-remove"></i>部门列表</p>
                        <div class="class-add">
                            <el-popover placement="top" width="160" v-model="classAisible"
                                popper-class="department-class-popover">
                                <div class="popover-add-title">
                                    <i class="el-icon-warning"></i>
                                    <span>部门名称</span>
                                </div>
                                <div class="popover-add-input">
                                    <el-input v-model="className" clearable placeholder="部门名称" maxlength="32"></el-input>
                                </div>
                                <div style="text-align: right" class="popover-add-foot">
                                    <el-button size="mini" @click="cleargroup">取消</el-button>
                                    <el-button size="mini" type="primary" @click="classSubmit">确定</el-button>
                                </div>
                                <div slot="reference" class="class-add-icon el-icon-plus"></div>
                            </el-popover>
                        </div>
                    </div>
                    <div class="class-left-bottom" v-if="classArray.length">
                        <div v-for="(item, index) in classArray" :key="index" @click="classClick(index, item.id)"
                            :class="['class_box_item', classIndex == index ? 'active' : '',]">
                            <i class="el-icon-folder-remove wjicon"></i>
                            <span class="addtext">{{ item.name }}</span>
                            <div>
                                <el-popover placement="top" width="160" v-model="item.checked" trigger="manual"
                                    popper-class="department-class-popover">
                                    <div class="popover-add-title">
                                        <i class="el-icon-warning"></i>
                                        <span>分类名称</span>
                                    </div>
                                    <div class="popover-add-input">
                                        <el-input v-model="className" clearable placeholder="分类名称" maxlength="32">
                                        </el-input>
                                    </div>
                                    <div style="text-align: right" class="popover-add-foot">
                                        <el-button size="mini" @click="clearEdit(index)">取消</el-button>
                                        <el-button size="mini" type="primary" @click="classEdit(item)">确定</el-button>
                                    </div>
                                    <i class="el-icon-edit class-edit" slot="reference" v-if="item.is_default == 0"
                                        @click.stop="classEditOpen(index)"></i>
                                </el-popover>
                            </div>
                            <div>
                                <el-popover placement="top" width="160" v-model="item.checked_delete" trigger="manual"
                                    popper-class="department-class-popover">
                                    <div class="popover-add-title">
                                        <i class="el-icon-warning"></i>
                                        <span>确定删除嘛</span>
                                    </div>
                                    <div style="text-align: right" class="popover-add-foot">
                                        <el-button size="mini" @click="clearDelete(index)">取消</el-button>
                                        <el-button size="mini" type="primary" @click="delectgroup(item)">确定</el-button>
                                    </div>
                                    <i class="el-icon-delete class-delete" slot="reference" v-if="item.is_default == 0"
                                        @click.stop="classDeleteOpen(index)"></i>
                                </el-popover>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="department-content">
                    <!-- 搜索 -->
                    <div class="global-search-box">
                        <div class="search-top-button">
                            <el-button type="primary" @click="top_button_add">添加员工</el-button>
                            <el-button type="primary" @click="batch_import">批量导入</el-button>
                            <el-button type="primary" @click="batch_create">批量生成员工</el-button>
                            <!-- <el-button type="primary" @click.stop="invitePerson">邀请员工</el-button> -->
                        </div>
                        <div class="search-content">
                            <div class="search-item">
                                <div class="search-label">创建时间：</div>
                                <div class="search-time-between">
                                    <el-date-picker v-model="noticeTime" type="datetimerange"
                                        :picker-options="pickerOptionLater" start-placeholder="开始日期" end-placeholder="结束日期"
                                        format="yyyy-MM-dd" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
                                </div>
                            </div>
                            <div class="search-item">
                                <el-input
                                    :placeholder="'请输入' + (shopnamesku == 0 ? '账号' : shopnamesku == 1 ? '手机号' : '身份证号')"
                                    v-model="staffIndex" clearable>
                                    <template slot="prepend">
                                        <el-select v-model="shopnamesku" placeholder="请选择">
                                            <el-option v-for="item in shopaddList" :key="item.value" :label="item.label"
                                                :value="item.value"></el-option>
                                        </el-select>
                                    </template>
                                </el-input>
                            </div>
                            <div class="search-item">
                                <label class="search-label">状态：</label>
                                <el-select v-model="typevalue" clearable>
                                    <el-option v-for="item in typeList" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="serach-buttom-button">
                            <el-button type="primary" @click="getTableData">搜索</el-button>
                            <el-button @click="replacement_search">重置条件</el-button>
                        </div>
                    </div>

                    <!-- 表格 -->
                    <div class="table-header">
                        <el-checkbox v-model="all_checked" @change="allchange"></el-checkbox>
                        <span>全选</span>
                        <el-button @click="piliang_on_off(1)">启用</el-button>
                        <el-button @click="piliang_on_off(0)">禁用</el-button>
                        <el-button @click="piliang_delete">删除</el-button>
                        <el-button @click="piliang_depart">设置部门</el-button>
                        <el-button @click="batch_export">批量导出</el-button>
                    </div>
                    <div class="department-table">
                        <div class="table">
                            <el-table ref="table" :data="tableData" style="width: 100%" @selection-change="singletable">

                                <el-table-column type="selection" min-width="55" align="center"></el-table-column>
                                <el-table-column width="100" prop="id" label="ID"></el-table-column>
                                <el-table-column width="240" label="企业名称">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.company?.name }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="150" prop="nickname" label="员工姓名"></el-table-column>
                                <el-table-column width="150" prop="binding" label="手机号"></el-table-column>
                                <el-table-column width="200" prop="id_card" label="身份证"></el-table-column>
                                <el-table-column width="150" prop="username" label="账号"></el-table-column>
                                <el-table-column width="100" label="状态">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.status == "0" ? '禁用' : '启用' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="140" label="部门">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.department?.name }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" prop="user_order_count" label="订单数量"></el-table-column>
                                <el-table-column width="200" prop="created_at" label="注册时间"></el-table-column>
                                <el-table-column width="200" label="操作" fixed="right" class-name="caozuo-btns"
                                    align="center">
                                    <template slot-scope="scope">
                                        <el-button type="text" @click="edit_staff(scope)">修改</el-button>
                                        <el-button type="text" @click="see_jifen(scope)">积分明细</el-button>
                                        <el-button type="text" @click="duihuan_staff(scope)">兑换记录</el-button>
                                        <el-button type="text" @click="delete_staff(scope)">删除</el-button>
                                        <el-button type="text" @click.stop="resetPassword(scope)">重置密码</el-button>
                                    </template>
                                </el-table-column>


                                <!-- 无数据 -->
                                <template slot="empty">
                                    <div class="empty_data">
                                        <p>暂无数据</p>
                                    </div>
                                </template>
                            </el-table>
                        </div>
                    </div>
                    <div class="pagination">
                        <el-pagination @size-change="handleSizeChange" :hide-on-single-page="false"
                            @current-change="handleCurrentChange" :current-page.sync="currentPage"
                            :page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
                            layout="total, sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-card>
        <el-dialog title="重置密码" :visible.sync="replacement" width="30%">
            <div class="replace_content">
                <el-form :model="passwordForm" label-width="120px" :rules="passwordRules">
                    <el-form-item label="新密码" prop="password">
                        <el-input v-model="passwordForm.password" type="password"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeattrubute()">取 消</el-button>
                <el-button class="addressqr" type="primary" @click="certitude()">确 定</el-button>
            </div>
        </el-dialog>
        <diy-import-more ref="diyImportMore" :dialogTitle="'导入'" :dialogImportUrl="'/staff/import'"
            :dialogTemplateUrl="'company.user.import.template'" @dialogImportCallback="importCallback"></diy-import-more>
        <!-- 导出 -->
        <diy-export-more ref="diyExportMore" :dialogTitle="'导出'" :dialogExportKeys="'user.export.field.list'"
            :dialogExportUrl="'/user/export'" :dialogExportIdList="dialogExportIdList"
            :dialogExportIsSearch="false"></diy-export-more>

        <!-- 设置部门弹窗 -->
        <el-dialog title="设置部门" :visible.sync="setDepartmentDialog" width="600px">
            <div class="depart-dialog-box">
                <span class="label"> 选择部门： </span>
                <el-cascader v-model="setDepartmentValue" :options="classArray" popper-class="pfz_cascader"
                    :props="{ value: 'id', label: 'name', children: 'children' }" clearable></el-cascader>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clearSetDepartment">取 消</el-button>
                <el-button class="addressqr" type="primary" @click="handleSetDepartment">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            classAisible: false, //新增分类气泡框
            className: "", //新增分类名称
            classArray: [], //分类数据集
            classIndex: 0, // 当前选中的分类
            noticeTime: "", // 搜索创建时间
            // 设定时间选择区间截止为当天
            pickerOptionLater: {
                disabledDate(time) {
                    var date = new Date(new Date().setHours(23, 59, 59, 59)).getTime()
                    return time.getTime() > date
                },
                // 所有的截止时间为当天的23.59.59
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const start = new Date().setHours(0, 0, 0, 0);
                        const end = new Date().setHours(23, 59, 59, 59);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '7天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 7 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '30天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 30 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            shopnamesku: "0",
            staffIndex: "",
            shopaddList: [{
                value: "0",
                label: "账号"
            }, {
                value: "1",
                label: "手机号"
            }, {
                value: "2",
                label: "身份证号"
            }],
            typevalue: "",
            typeList: [{
                value: "1",
                label: "启用"
            }, {
                value: "0",
                label: "禁用"
            }],
            // 表格数据
            tableData: [],
            multipleSelection: [],// 全选选中项
            currentPage: 1, // 当前页数
            eachPage: 10, // 每页大小
            total_entry: 0, // 总条数
            replacement: false,
            newPerson: "",   //修改密码的员工
            passwordForm: {
                password: '',
            },
            passwordRules: {
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
            },
            company_id: this.$route.query.company_id || '0',
            active_id: this.$route.query.active_id || this.$route.query.activity_id || "0",
            dialogExportIdList: [],
            all_checked: false,
            setDepartmentDialog: false,
            setDepartmentValue: ""
        }
    },
    created() {
        // 获取部门列表
        this.getClass()
        // 获取员工列表
        this.getTableData()
    },
    methods: {
        // 获取部门列表
        getClass() {
            var dep_url = '/company/department/list';
            if (this.$route.query['company_id'] > 0) {
                dep_url = '/company/' + this.$route.query['company_id'] + '/department/list';
            }

            this.$get(dep_url).then(res => {
                this.classArray = res.data.list;
                this.classArray.forEach(item => {
                    // 编辑气泡框
                    item['checked'] = false
                    // 删除气泡框
                    item['checked_delete'] = false
                })
            })
        },
        // 关闭气泡框，清空输入框
        cleargroup() {
            this.classAisible = false;
            this.className = '';
        },
        // 添加分类组
        classSubmit() {
            if (!this.className) {
                this.$message.error("请输入分类名称");
                return;
            }

            let data = {
                name: this.className,
                parent_id: 0,
                level: 0,
                company_id: this.$route.query['company_id'],
                sort: 0
            };

            this.$post('/company/department', data).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.message);
                    this.getClass();
                    this.className = '';
                    this.classAisible = false;
                } else {
                    this.common.message(this, res.message);
                }
            }).catch(err => {
                this.common.message(this, err.message);
            });
        },
        // 选择分类
        classClick(index, id) {
            if (this.classIndex != index) {
                this.classIndex = index;
                this.getTableData()
            }
        },
        // 取消分组的修改
        clearEdit(index) {
            this.classArray[index].checked = false;
            this.className = ""
            this.$forceUpdate()
        },
        // 取消分组的删除
        clearDelete(index) {
            this.classArray[index].checked_delete = false;
            this.$forceUpdate()
        },
        // 确认分组的修改
        classEdit(item) {
            if (!item.name) {
                this.$message.error("请输入分类名称");
                return;
            }

            let data = {
                name: this.className,
                parent_id: 0,
                level: 0,
                company_id: this.$route.query['company_id'],
                sort: 0
            };

            this.$put('/company/department/' + item.id, data).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.message);
                    this.className = '';
                    this.getClass();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.$message.error(err.message);
            });
        },
        // 打开修改分组的气泡框
        classEditOpen(index) {
            this.classArray[index].checked = true;
            this.className = this.classArray[index].name
            this.$forceUpdate()
        },
        // 打开删除分组的气泡框
        classDeleteOpen(index) {
            this.classArray[index].checked_delete = true;
            this.$forceUpdate()
        },
        // 删除分类组
        delectgroup(item) {
            this.$delete('/company/department/' + item.id).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.message);
                    this.getClass();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.$message.error(err.message);
            });
        },
        // 批量设置部门
        piliang_depart() {
            if (!this.multipleSelection.length) {
                this.$message.warning(`请选择需要设置部门的员工`);
                return false
            }
            this.setDepartmentDialog = true
        },
        // 确认修改部门
        handleSetDepartment() {
            if (!this.setDepartmentValue) return this.$message.warning('请选择部门')

            const id = this.multipleSelection.map(item => item.id)
            this.$put('/staff/departmentBatch', { id, department_id: this.setDepartmentValue.pop() }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.message);
                    this.$refs.table.clearSelection();
                    this.setDepartmentValue = "";
                    this.setDepartmentDialog = false;

                    this.getTableData()
                } else {
                    this.$message.warning(res.message);
                }
            })
        },
        // 取消修改部门
        clearSetDepartment() {
            this.$refs.table.clearSelection();
            this.setDepartmentValue = "";
            this.setDepartmentDialog = false;
        },
        // 切换分页
        handleSizeChange(val) {
            this.eachPage = val;
            this.getTableData()
        },
        // 切换每页大小
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData()
        },
        // 添加员工
        top_button_add() {
            this.$router.push(`/code/index/company_activity_staff_add?company_id=${this.$route.query.company_id}`)
        },
        // 全选
        allchange(value) {
            // this.$refs.table.toggleAllSelection()
            if (!value) {
                this.$refs.table.clearSelection();
            } else {
                this.$refs.table.toggleAllSelection();
            }
            this.all_checked = value;
        },

        // 单个表格被选中
        singletable(val) {
            this.multipleSelection = val;
            if (val.length == this.tableData.length) {
                this.all_checked = true;
            } else {
                this.all_checked = false;
            }
        },
        // 编辑员工
        edit_staff(scope) {
            this.$router.push(`/code/index/staff-edit?company_id=${this.$route.query.company_id}&id=${scope.row.id}`)
        },
        // 兑换详情
        duihuan_staff(scope) {
            this.$router.push(`/information/staff_detail?company_id=${this.$route.query.company_id}&user_id=${scope.row.id}`)
        },
        // 批量启用/关闭
        piliang_on_off(value) {
            if (!this.multipleSelection.length) {
                this.$message.warning(`请选择需要${value ? '启用' : '禁用'}的员工`);
                return false
            }
            this.$confirm(`确定要将该员工${value ? '启用' : '禁用'}吗?`, "警告", {
                type: "warning"
            }).then(() => {
                const id = this.multipleSelection.map(item => item.id)
                this.$put('/staff/statusBatch', { id, status: value }).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.message);
                        this.getTableData()
                    } else {
                        this.$message.warning(res.message);
                    }
                })
            }).catch(_ => { })
        },
        // 批量删除
        piliang_delete() {
            if (!this.multipleSelection.length) {
                this.$message.warning(`请选择需要删除的员工`);
                return false
            }
            this.$confirm(`确定要将该员工删除吗?`, "警告", {
                type: "warning"
            }).then(() => {
                const id = this.multipleSelection.map(item => item.id)
                this.$delete('/staff', { id }).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                        this.getTableData()
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }).catch(_ => { })
        },
        // 查看积分
        see_jifen(scope) {
            this.$router.push(`/code/index/integral-list?id=${scope.row.id}`)
        },
        // 删除员工
        delete_staff(scope) {
            this.$confirm(`确定要将员工【${scope.row.nickname}】删除吗?`, "警告", {
                type: "warning"
            }).then(() => {
                this.$delete('/staff', { id: [scope.row.id] }).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                        this.getTableData()
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }).catch(_ => { })
        },
        // 整理搜索数据
        extractSearch() {
            let _data = {
                search: [],
                page: this.currentPage,
                limit: this.eachPage,
                order: [
                    {
                        "key": "id",
                        "value": "desc"
                    }
                ]
            };
            if (this.classArray.length) {
                const obj = {
                    "key": "department_id",//部门
                    "value": this.classArray[this.classIndex].id,
                    "op": "equal"
                }
                _data.search.push(obj)
            }
            if (this.noticeTime) {
                const obj = {
                    "key": "created_at",//时间
                    "value": [this.common.timeStampTurnTime(this.noticeTime[0], 'yyyy-MM-dd hh:mm:ss'), this.common.timeStampTurnTime(this.noticeTime[1], 'yyyy-MM-dd hh:mm:ss')],
                    "op": "between"
                }
                _data.search.push(obj)
            }
            if (this.staffIndex) {
                const obj = {
                    "key": this.shopnamesku == 0 ? 'username' : this.shopnamesku == 1 ? 'binding' : 'id_card',//账号...
                    "value": this.staffIndex,
                    "op": "search"
                }
                _data.search.push(obj)
            }
            if (this.typevalue) {
                const obj = {
                    "key": 'status',//状态...
                    "value": this.typevalue,
                    "op": "equal"
                }
                _data.search.push(obj)
            }
            return _data
        },
        // 获取列表数据
        getTableData() {
            const requestData = this.extractSearch();
            var staff_url = '/staff/list';
            if (this.$route.query.company_id >= 0) {
                staff_url = `/staff/list/co_${this.$route.query.company_id}`;
            }

            this.$get(staff_url, requestData).then(res => {
                if (res.code == 200) {
                    this.tableData = res.data.list
                    this.total_entry = res.data.total
                }
            })
        },
        resetPassword(scope) {
            this.newPerson = scope.row;
            this.replacement = !this.replacement;
        },
        // 确认修改密码
        certitude() {
            let that = this;
            if (!this.passwordForm.password) {
                that.$message({
                    type: "warning",
                    message: "请填写密码",
                    showClose: true,
                    offset: 200,
                    duration: 1000,
                });
            }
            let params = {
                company_id: this.$route.query['company_id'],
                password: this.passwordForm.password,
            };
            this.$put(this.$apis.editStaffPassword + this.newPerson.id, params).then((res) => {
                if (res.code == 200) {
                    that.$message({
                        type: "success",
                        message: "修改成功",
                        showClose: true,
                        offset: 200,
                        duration: 1000,
                    });
                    setTimeout(() => {
                        that.replacement = !that.replacement;
                    }, 1500)
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                this.$message.error(err.message);
            })
        },
        closeattrubute() {
            this.replacement = !this.replacement;
        },
        // 批量导入
        batch_import() {

            this.$refs.diyImportMore.importMore = true;
        },
        importCallback() {
            this.getTableData();
        },
        // 批量生成
        batch_create() {
            this.$router.push({
                path: "/code/batch/create",
                query: {
                    active_id: this.active_id,
                    company_id: this.company_id
                }
            })
        },
        replacement_search() {
            this.noticeTime = "";
            this.staffIndex = "";
            this.typevalue = "";
            this.getTableData()
        },
        batch_export() {
            if (!this.multipleSelection.length) {
                this.$message.warning(`请选择需要导出的员工`);
                return false
            }
            let arr = this.multipleSelection.map(item => item.id);
            this.$refs.diyExportMore.export_user_id = arr;
            this.$refs.diyExportMore.exportMore = true;
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #535353 !important;
}

.depart-dialog-box {
    padding: 20px 30px;

    .label {
        width: 100px;
    }
}

.caozuo-btns {
    .el-button {
        // padding: 6px 3px;
    }
}

.department-box {
    display: flex;
    align-items: flex-start;

    .department-class {
        width: 200px;
        // height: 500px;
        height: calc(100vh - 94px);
        overflow-y: auto;
        padding-right: 20px;
        border-right: 1px solid #ededed;
        flex-shrink: 0;

        .class-left-up {
            display: flex;
            align-items: center;
            justify-content: space-between;

            >p {
                font-size: 16px;
                color: #333;
                font-weight: 500;
            }

            .class-add {
                cursor: pointer;
            }
        }
    }

    .department-class::-webkit-scrollbar {
        width: 10px;
    }

    .class-add-icon {
        font-size: 17px;
        color: #666;
        cursor: pointer;
    }

    .class-left-bottom {
        margin-top: 10px;

        .class_box_item {
            height: 32px;
            line-height: 32px;
            width: 100%;
            font-size: 14px;
            padding: 0 8px 0 12px;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
            position: relative;
            transition: .3s;
        }

        .class_box_item.active {
            color: var(--fontColor, #fb6638) !important;
            background: var(--primary-tips, #fff3ef) !important;
        }

        .class_box_item .addtext {
            margin-left: 18px;
            display: inline-block;
            max-width: 106px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .class_box_item .wjicon {
            position: absolute;
            top: 9px;
            left: 10px;
        }

        .class_box_item .class-delete {
            position: absolute;
            top: 10px;
            right: 8px;
            cursor: pointer;
            display: none;
        }

        .class_box_item:hover {
            color: var(--fontColor, #fb6638) !important;
            background: var(--primary-tips, #fff3ef) !important;
        }

        .class_box_item:hover .class-delete {
            display: block;
        }

        .class_box_item .class-edit {
            position: absolute;
            top: 10px;
            right: 30px;
            cursor: pointer;
            display: none;
        }

        .class_box_item:hover .class-edit {
            display: block;
        }
    }

    .department-content {
        padding-left: 20px;
        flex: 1;
        width: 0;

        .table-header {
            margin-top: 20px;
            padding-left: 18px;

            >span {
                padding: 0 10px;
                font-size: 14px;
            }

            .el-button {
                padding: 6px 6px;
            }
        }

        .department-table {
            width: 100%;
            display: -webkit-box;
            margin-top: 20px;

            .table {
                width: 100%;
            }
        }

        .pagination {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;
        }
    }

}

.department-class-popover {
    .popover-add-title {
        font-size: 14px !important;
        margin-bottom: 10px;

        i {
            color: #fbc712;
            margin-right: 4px;
        }
    }

    .popover-add-input {
        width: 100%;
        height: 38px;
        margin: 10px 0;

        .el-input {
            width: 160px;
        }

    }

    .popover-add-foot {
        /deep/ .el-button--mini {
            padding: 7px 15px !important;
        }
    }


}

.replace_content {
    margin: 20px 0;

    /deep/.el-input {
        width: 90%;
    }
}
</style>